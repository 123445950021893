import { selector } from 'recoil';
import FormConfig from '../atom/FormConfig';
import ScreenProgress from '../atom/ScreenProgress';
import EngagementListAtom from '../atom/EngagementList';

export default selector({
	get: ({ get }) => {
		const { formType, id, screens } = get(FormConfig);
		const currentScreen = get(ScreenProgress);
		const { previousStep, engagedItems } = get(EngagementListAtom);
		return {
			currentScreen,
			engagedItems,
			formType,
			id,
			previousStep,
			screens,
		};
	},
	key: 'Tracking',
});
