import React, { useRef, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { setLabelText } from 'utils/setLabelText';
import { IbanEvent } from './Constants';
import { IbanProps, IbanValidateEvent } from '../interfaces';
import UserBankDataAtom from '../stores/atom/UserBankData';
import { getIbanValidation } from '../api/getIbanValidation';
import { IbanWebComponent } from '../interfaces/IbanWebComponent';

import '@oev-dxp/oev-components/dist/iban';
import '@oev-dxp/oev-components/dist/text-input-v1';

function Iban({ ibanConfigData }: Readonly<IbanProps>) {
	const isRequired = ibanConfigData?.mandatory?.choice === 'mandatory';
	const [userBankData, setUserBankData] = useRecoilState(UserBankDataAtom);
	const ibanRef = useRef<IbanWebComponent | null>(null);
	const isRequiredRef = useRef(userBankData.isRequired);

	function errorCallBack() {
		if (ibanRef.current) ibanRef.current.validity = { customError: true, valid: false };
	}

	function invalidateUserBankData() {
		setUserBankData({
			electronicFormatIBAN: '',
			financialInstitution: '',
			friendlyFormatIBAN: '',
			ibanLabel: ibanConfigData?.labelIban || 'IBAN',
			isRequired,
		});

		if (ibanRef?.current) ibanRef.current.validity = { customError: false, valid: false };
	}

	async function validateIban(ev: IbanValidateEvent) {
		invalidateUserBankData();
		const res = await getIbanValidation(ev?.detail?.electronicFormatIBAN || '', errorCallBack);
		if (!res || !res?.geldInstitut || !res?.iban) {
			errorCallBack();
			return;
		}

		setUserBankData({
			electronicFormatIBAN: ev?.detail?.electronicFormatIBAN,
			financialInstitution: res?.geldInstitut,
			friendlyFormatIBAN: ev?.detail?.friendlyFormatIBAN,
			ibanLabel: ibanConfigData?.labelIban || 'IBAN',
			isRequired,
		});

		if (ibanRef.current) ibanRef.current.validity = { customError: false, valid: true };
	}

	function isRequiredHandler(ev: CustomEvent) {
		if (ev.detail.value.length !== 0 && !isRequired) {
			setUserBankData((prevData) => {
				const updatedData = { ...prevData, isRequired: true };
				isRequiredRef.current = true;
				return updatedData;
			});
		}
	}

	useEffect(() => {
		setUserBankData((prevData) => ({ ...prevData, isRequired }));
		const { current } = ibanRef;
		current?.addEventListener(IbanEvent.validate, (ev: IbanValidateEvent) => validateIban(ev));
		current?.addEventListener(IbanEvent.invalidate, () => invalidateUserBankData());
		current?.addEventListener(IbanEvent.change, (ev: CustomEvent) => isRequiredHandler(ev));

		return () => {
			current?.removeEventListener(IbanEvent.validate, (ev: IbanValidateEvent) => validateIban(ev));
			current?.removeEventListener(IbanEvent.invalidate, () => invalidateUserBankData());
			current?.removeEventListener(IbanEvent.change, (ev: CustomEvent) => isRequiredHandler(ev));
		};
	}, []);

	useEffect(() => {
		isRequiredRef.current = userBankData.isRequired;
	}, [userBankData.isRequired]);

	return (
		<oev-iban
			{...(isRequired ? { required: '' } : null)}
			errormessage-iban-validation={ibanConfigData?.errorMessageIbanValidation}
			id="Iban"
			inline-label={ibanConfigData?.inlineLabelIban}
			label-bank={ibanConfigData?.labelBank}
			label-iban={setLabelText(ibanConfigData?.labelIban || '', isRequired)}
			placeholder-bank={ibanConfigData?.placeholderBank}
			placeholder-iban={ibanConfigData?.placeholderIban}
			ref={ibanRef}
			regex-iban={ibanConfigData?.regexIban}
			required-error-message={ibanConfigData?.mandatory?.errorMessage}
			tooltip={ibanConfigData?.tooltip}
			value-financial-institution={userBankData?.financialInstitution}
			value-iban={userBankData?.electronicFormatIBAN || null}
		/>
	);
}

export default Iban;
