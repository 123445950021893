import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import ScreenFactory from '../../factories/ScreenFactory';
import FormConfig from '../../stores/atom/FormConfig';
import FormHiddenFields from '../../stores/atom/FormHiddenFields';
import AgencyData from '../../features/Agency/stores/atom/AgencyData';
import EngagementListAtom from '../../stores/atom/EngagementList';
import wasFormSubmitted from '../../stores/atom/wasFormSubmitted';
import ScreenContext from '../../stores/selector/ScreenContextSelector';
import TrackingSelector from '../../stores/selector/TrackingSelector';
import ActiveScreenSelector from '../../stores/selector/ActiveScreenSelector';
import SummaryAtom from '../../stores/atom/SummaryAtom';
import SelectedTariff from '../../features/TariffComparison/stores/atom/SelectedTariff';
import useFormConfiguration from '../../hooks/formular/useFormConfiguration';
import useAgencyCardData from '../../hooks/formular/useFormAgencyData';
import LastPage from '../../view/LastPage';
import { ErrorView } from '../../view/ErrorView';
import { ThemeSwitcher } from '../../view/ThemeSwitcher';
import Debugging from '../../utils/Debugging';
import { updateEngagementList } from '../../utils/updateEngagementList';
import dataLayerHandler from '../../utils/tracking/dataLayerHandler';
import { TrackingEvent } from '../constants';
import { FormConfiguration } from './interfaces';
import { ScreenProps } from '../../interfaces/Screen';
import { ScreenElements } from '../../interfaces/ScreenElements';
import { Component } from '../../interfaces';

function FormularConfiguration({
	adMandant,
	agencyId,
	configMandant,
	formId,
	theme = 'whitelabel',
}: Readonly<FormConfiguration>) {
	if (!formId || !configMandant) {
		return (
			<ErrorView
				codeExample="<div id=&quot;root&quot; data-config-mandant=&quot;svag&quot; data-formid=&quot;id&quot;>"
				text="Missing data-formid and / or data-config-mandant as data attribute in the HTML root element. Example:"
			/>
		);
	}
	const { config: formConfigData } = useFormConfiguration(formId, configMandant);
	const { agencyData, error: agencyDataError, isLoading: agencyDataIsLoading } = useAgencyCardData(agencyId ?? '', adMandant ?? '');
	const setAgencyData = useSetRecoilState(AgencyData);
	const setFormConfig = useSetRecoilState(FormConfig);
	const setFormHiddenFields = useSetRecoilState(FormHiddenFields);
	const setSummaryAtom = useSetRecoilState(SummaryAtom);
	const { finalScreen, currentScreen, isDebuggingMode } = useRecoilValue(ScreenContext);
	const isFinalScreen = finalScreen === currentScreen;
	const [engagementList, setEngagementList] = useRecoilState(EngagementListAtom);
	const [hasInitializedHiddenFields, setHasInitializedHiddenFields] = useState<boolean>(false);
	const trackingData = useRecoilValue(TrackingSelector);
	const activeScreen = useRecoilValue(ActiveScreenSelector);
	const wasSubmitted = useRecoilValue(wasFormSubmitted);
	const selectedTariff = useRecoilValue(SelectedTariff);

	useEffect(() => {
		if (agencyDataIsLoading || agencyDataError) return;
		if (!agencyData || !Object.keys(agencyData).length) return;
		setAgencyData({ ...agencyData, AD_CONTEXT_IS_AVAILABLE: !!agencyData?.nummer });
	}, [agencyData]);

	// triggers initial push to the data layer when the
	// form data (e.g. trackingData.id) is no longer undefined
	useEffect(() => {
		if (!trackingData.id) return;
		setEngagementList({
			...engagementList,
			previousStep:
				{ id: activeScreen?.id },
		});
		dataLayerHandler({ event: TrackingEvent.formularInitialPageView, trackingData });
	}, [trackingData.id]);

	useEffect(() => {
		if (!formConfigData) return;

		// don't send another step event after successful submit
		if (wasSubmitted) {
			// cleanup
			return setEngagementList({ ...engagementList, engagedItems: [] });
		}

		dataLayerHandler({
			event: TrackingEvent.formularStep,
			trackingData,
			...(selectedTariff?.tariffName && { tariffName: selectedTariff?.tariffName }),
		});

		setEngagementList({ ...engagementList, engagedItems: [] });
	}, [currentScreen, wasSubmitted]);

	// TODO add focus to all web components (upload)
	useEffect(() => {
		if (!formConfigData) return;
		setFormConfig(formConfigData);

		// initialize hidden fields only once
		if (!hasInitializedHiddenFields) {
			setFormHiddenFields(formConfigData?.hiddenFields);
			setHasInitializedHiddenFields(true);
		}

		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		initializeFieldsForSummary(formConfigData?.screens);
	}, [formConfigData]);

	// ToDo: Refactor to reduce complexity
	// https://github.com/zakirullin/cognitive-load?tab=readme-ov-file#nested-ifs
	function initializeFieldsForSummary(screens: ScreenProps[]) {
		const result: { [key: number]: string[]; } = {};

		screens?.forEach((screenElement: ScreenProps, index: number) => {
			const screenProperties: string[] = [];

			screenElement.elements.forEach((sectionEl: ScreenElements) => {
				if (sectionEl.section) {
					sectionEl.section.elements?.forEach((sectionElement) => {
						// handle fields in row
						if (sectionElement.row) {
							sectionElement.row.components.forEach((component) => {
								const single = Object.values(component)
									.find((singleComp: Component) => singleComp !== null);
								if (single) {
									const key = single.data.name || single.data.uuid;
									screenProperties.push(key);
								}
							});
						}
						// handle agent as field
						if (sectionElement.agent) screenProperties.push('Agency');
						// handle tariffComparison as field
						if (sectionElement.tariffComparison) screenProperties.push('TariffComparison');
						if (sectionElement.iban) screenProperties.push('Iban');
					});
				}
			});
			result[index] = screenProperties;
		});
		setSummaryAtom(result);
	}

	// TODO add focus to all web components (upload)
	useEffect(() => {
		if (!formConfigData) return;
		setFormConfig(formConfigData);
		initializeFieldsForSummary(formConfigData?.screens);
	}, [formConfigData]);

	const checkIfDebuggingMode = isDebuggingMode ? (
		<div onFocus={(e) => updateEngagementList(e, setEngagementList, engagementList)}>
			<ThemeSwitcher theme={theme} />
			<ScreenFactory key={formConfigData?.id} />
			<LastPage />
			<Debugging />
		</div>
	) : (
		<div
			onFocus={(e) => updateEngagementList(e, setEngagementList, engagementList)}
		>
			<ScreenFactory key={formConfigData?.id} />
		</div>
	);
	return isFinalScreen ? <LastPage /> : checkIfDebuggingMode;
}

export default FormularConfiguration;
