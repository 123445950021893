import { selector } from 'recoil';
import { FileMetaData } from 'components/Upload';
import AgencyData from 'features/Agency/stores/atom/AgencyData';
import UserBankDataAtom from 'features/Iban/stores/atom/UserBankData';
import SelectedTariff from 'features/TariffComparison/stores/atom/SelectedTariff';
import CurrentScreenDataSelector from './CurrentScreenDataSelector';
import FilteredData from '../atom/FilteredData';
import FormDataAtomProps, { MetaData } from '../atom/FormDataMetaDataAtom';
import SelectedAgencyAtom from '../atom/SelectedAgency';
import FormHiddenFields from '../atom/FormHiddenFields';
import RenderElementsAtom, { RenderElements } from '../atom/RenderElements';
import { isUUID } from '../../utils';
import { HiddenFields } from '../../interfaces/HiddenFields';

export function isValidJSON(string: string) {
	try {
		JSON.parse(string);
	} catch (e) {
		return false;
	}
	return true;
}

function getCorrectValue(value: any, formDataProp: MetaData) {
	// extract storageId when value has content of upload component
	if (formDataProp?.type === 'oev-upload-v1' && isValidJSON(value)) {
		const storageIds = Array
			.from(JSON.parse(value) as Array<FileMetaData>)
			.map((file: { storageId: string; }) => file.storageId);
		return storageIds.toString();
	}
	return value;
}

function handleHiddenFields(hiddenFields: HiddenFields[], renderElements: RenderElements) {
	return hiddenFields?.reduce((prev, hiddenField) => {
		if (renderElements[hiddenField.name]) {
			const value = hiddenField.defaultValue ?? '';
			return {
				...prev,
				[hiddenField.name]: value,
			};
		}

		return prev;
	}, {});
}

// ToDo: technical debt - refactor reducer
export default selector({
	get: ({ get }) => {
		const { all: allData } = get(CurrentScreenDataSelector);
		const agencyData = get(AgencyData);
		const elementProps = get(FormDataAtomProps);
		const filteredData = get(FilteredData);
		const formHiddenFields = get(FormHiddenFields);
		const renderElement = get(RenderElementsAtom);
		const selectedAgency = get(SelectedAgencyAtom);
		const selectedTariff = get(SelectedTariff);
		const userBankData = get(UserBankDataAtom);

		let agency: { Agenturnummer: string; Niederlassungsordnung?: number; } | null = null;
		let tariff: { TarifId: string; TarifName: string; signedTariffOffer: string; } | null = null;
		let iban: { 'OEV-IBAN': string; } | null = null;

		agency = (selectedAgency.Agenturnummer || agencyData.nummer) ? {
			Agenturnummer: selectedAgency.Agenturnummer || agencyData.nummer || '',
			Niederlassungsordnung: selectedAgency.orderNumber ?? 0,
		} : null;

		tariff = (
			selectedTariff.tariffId
			&& selectedTariff.tariffName
			&& selectedTariff.signedTariffOffer
		) ? {
				TarifId: selectedTariff.tariffId,
				TarifName: selectedTariff.tariffName,
				signedTariffOffer: selectedTariff.signedTariffOffer,
			} : null;

		iban = userBankData.electronicFormatIBAN ? {
			'OEV-IBAN': userBankData.electronicFormatIBAN,
		} : null;

		// filter the keys from out of allData and create a flatten Object for Submit
		// rm uuid from data on submit
		return Object.keys(allData).filter((key) => !isUUID(key) && !filteredData.includes(key))
			.reduce((prev, key) => ({
				...handleHiddenFields(formHiddenFields, renderElement),
				...prev,
				[key]: getCorrectValue(allData[key], elementProps[key]),
				...agency,
				...tariff,
				...iban,
			}), {});
	},
	key: 'SubmitData',
});
