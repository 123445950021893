export enum TrackingEvent {
	formularError = 'formular_error',
	formularExit = 'formular_navigation',
	formularInitialPageView = 'formular_initial',
	formularStep = 'formular_step',
	formularSubmit = 'formular_submit',
	formularSearch = 'formular_agencysearch',
	profilerCalculate = 'profiler_calculate',
	profilerCalculateError = 'profiler_calculate_error',
}

export enum FormType {
	b2bProductLead = 'b2b_produkt_lead',
	b2cProductLead = 'b2c_produkt_lead',
	career = 'karriere',
	contactLead = 'kontakt_lead',
	offer = 'angebot',
	other = 'sonstiges',
	productLead = 'produkt_lead',
	productSale = 'produkt_sale',
	reportDamage = 'schaden_melden',
	requestService = 'leistung_beantragen',
	service = 'service',
}
