import { ContentType, HTTPHeader } from 'apiClient/restLayer/Constants';
import { BankDataResponse } from 'features/Iban/interfaces';
import { fetchGetRequest } from '../restLayer';
import { Service } from './Constants';
import getFormGatewayUrl from './formGatewayUrl';

export default async function ibanValidation(
	iban: string,
) {
	const formGatewayUrl = getFormGatewayUrl();
	const ibanServiceUrl = `${formGatewayUrl}/${Service.ibanValidation}/${iban}`;
	const headers: HeadersInit = {
		[HTTPHeader.accept]: ContentType.json,
	};

	return fetchGetRequest(ibanServiceUrl, headers)
		.then((payload) => payload as BankDataResponse);
}
