import { atom } from 'recoil';
import { FormSequence } from '../../interfaces/FormSequence';
import { FormType } from '../../components/constants';

const FormConfig = atom<FormSequence>({
	default: {
		formType: FormType.offer,
		hiddenFields: [],
		id: '',
		name: '',
		progressTabs: false,
		screens: [],
	},
	key: 'FormConfig',
});

export default FormConfig;
