import React, { useEffect } from 'react';
import {
	useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState,
} from 'recoil';
import { customAlphabet } from 'nanoid';
import FormularConfiguration from './components/FormularConfiguration/FormularConfiguration';
import ScreenProgress from './stores/atom/ScreenProgress';
import { TrackingEvent } from './components/constants';
import getInvalidFieldsWithError from './utils/tracking/getInvalidFieldsWithError';
import FormAtomData, { FormData } from './stores/atom/FormData';
import dataLayerHandler from './utils/tracking/dataLayerHandler';
import TrackingSelector from './stores/selector/TrackingSelector';
import wasFormSubmitted from './stores/atom/wasFormSubmitted';
import FormRegion from './stores/atom/FormRegion';
import FormAdMandant from './stores/atom/FormAdMandant';
import FormConfigMandant from './stores/atom/FormConfigMandant';
import FormProductOwner from './stores/atom/FormProductOwner';
import SessionId from './stores/atom/SessionId';
import PageConfig, { PageConfigData } from './stores/atom/PageConfig';
import FormOverride, { FormOverrideData } from './stores/atom/FormOverride';
import UserSessionOverride, { UserSessionOverrideData } from './stores/atom/UserSessionOverride';
import FormId from './stores/atom/FormId';
import FormMandant from './stores/atom/FormMandant';
import FormDataContentRoot from './stores/atom/formDataContentRoot';
import InitialScreenRender from './stores/atom/isInitialScreenRender';
import { AppProps } from './interfaces';
import SelectedTariff from './features/TariffComparison/stores/atom/SelectedTariff';

function App({
	adMandant,
	agencyId,
	configMandant,
	contentRoot,
	formId,
	formOverride,
	mandant,
	pageConfig,
	productOwner,
	region,
	theme,
	userSessionOverride,
}: Readonly<AppProps>) {
	// ToDo: refactor all bootstrap recoil atoms into one
	const currentScreenIndex = useRecoilValue(ScreenProgress);
	const [, setIsInitialScreenRender] = useRecoilState(InitialScreenRender);
	const setFormAdMandant = useSetRecoilState(FormAdMandant);
	const setFormConfigMandant = useSetRecoilState(FormConfigMandant);
	const setFormDataContentRoot = useSetRecoilState(FormDataContentRoot);
	const setFormDataRegion = useSetRecoilState(FormRegion);
	const setFormId = useSetRecoilState(FormId);
	const setFormMandant = useSetRecoilState(FormMandant);
	const setProductOwner = useSetRecoilState(FormProductOwner);
	const setSessionId = useSetRecoilState(SessionId);
	const setPageConfig = useSetRecoilState(PageConfig);
	const setFormOverride = useSetRecoilState(FormOverride);
	const setUserSessionOverride = useSetRecoilState(UserSessionOverride);
	const onDismountPush = useRecoilCallback(({ snapshot }) => async () => {
		// will not be called in magnolia. Why?
		const trackingData = await snapshot.getPromise(TrackingSelector);
		const currentScreen = await snapshot.getPromise(ScreenProgress);
		const formData = await snapshot.getPromise(FormAtomData);
		const invalidFields = getInvalidFieldsWithError(formData[currentScreen as keyof FormData]);
		const wasSubmitted = await snapshot.getPromise(wasFormSubmitted);
		const selectedTariff = await snapshot.getPromise(SelectedTariff);
		window.dataLayer = window?.dataLayer || [];
		// should not push formular_exit when form has been submitted successfully
		if (wasSubmitted) return;

		dataLayerHandler({
			event: TrackingEvent.formularExit,
			invalidFields,
			trackingData,
			...(selectedTariff?.tariffName && { tariffName: selectedTariff?.tariffName }),
		});
	});
	const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
	const nanoid = customAlphabet(alphabet, 16);

	useEffect(() => {
		window.addEventListener('beforeunload', onDismountPush);
		setFormAdMandant(adMandant || '');
		setFormConfigMandant(configMandant || '');
		setFormDataContentRoot(contentRoot || '');
		setFormDataRegion(region || '');
		setFormId(formId || '');
		setFormMandant(mandant || '');
		setFormOverride(JSON.parse(formOverride || '{}') as FormOverrideData);
		setPageConfig(JSON.parse(pageConfig || '{}') as PageConfigData);
		setProductOwner(productOwner || '');
		setSessionId(nanoid());
		setUserSessionOverride(JSON.parse(userSessionOverride || '{}') as UserSessionOverrideData);
		if (process.env.NODE_ENV === 'development') import('@oev-dxp/oev-components/dist/all.css');
	}, []);

	useEffect(() => {
		if (!currentScreenIndex) return;
		setIsInitialScreenRender(true);
	}, [currentScreenIndex]);

	return (
		<FormularConfiguration
			adMandant={adMandant}
			agencyId={agencyId}
			configMandant={configMandant}
			formId={formId}
			theme={theme}
		/>
	);
}

export default App;
