import React from 'react';
import { useRecoilValue } from 'recoil';
import UserBankDataAtom from 'features/Iban/stores/atom/UserBankData';
import { SubHeadLineDesktop, SubHeadLineMobile } from './Summary.style';
import Headline from '../../Headline/Headline';
import Item from './Item';
import Separator from './Separator';
import { SectionData } from '../../../stores/selector/SummarySelector';
import usePlaceholderResolver from '../../../hooks/usePlaceholderResolver';
import SelectedAgencyAtom from '../../../stores/atom/SelectedAgency';

interface ContentProps {
	isDesktopView: boolean;
	sectionData: SectionData;
	sectionIndex: number;
	summaryScreenLength: number;
}

function Content({
	sectionData, isDesktopView, sectionIndex, summaryScreenLength,
}: Readonly<ContentProps>) {
	const placeholderResolver = usePlaceholderResolver();
	const resolvedHeadline = placeholderResolver(sectionData?.headline || null);
	const { value: { Agenturname, Agenturadresse }, label } = useRecoilValue(SelectedAgencyAtom);
	const {
		electronicFormatIBAN,
		financialInstitution,
		friendlyFormatIBAN,
		ibanLabel,
	} = useRecoilValue(UserBankDataAtom);

	return (
		<>
			{/* checks if section have a headline */}
			{sectionData.headline ? (
				<div style={isDesktopView ? SubHeadLineDesktop : SubHeadLineMobile}>
					<Headline subheadline={resolvedHeadline} variant="headline-3" />
				</div>
			) : null}
			<oev-text-content>
				<oev-row-v1>
					{Object.keys(sectionData.values).map((field: string) => (
						<React.Fragment key={`-${field}`}>
							{/* to avoid empty span's in the code */}
							{sectionData.values[field].value?.length > 0 ? (
								<oev-column-v1>
									<Item {...sectionData.values[field]} />
								</oev-column-v1>
							) : null}
							{field === 'Agency' && Agenturname ? (
								<oev-column-v1>
									<Item
										{...sectionData.values[field]}
										label={label}
										// ToDo: Refactor state to handle more than just key-value pairs and remove this
										value={JSON.stringify(
											{ agencyAddress: Agenturadresse, agencyName: Agenturname },
										)}
									/>
								</oev-column-v1>
							) : null}
							{field === 'Iban' && electronicFormatIBAN ? (
								<oev-column-v1>
									<Item
										{...sectionData.values[field]}
										label={ibanLabel}
										value={`${friendlyFormatIBAN || electronicFormatIBAN} - ${financialInstitution}`}
									/>
								</oev-column-v1>
							) : null}
						</React.Fragment>
					))}
				</oev-row-v1>
			</oev-text-content>
			{/* if last element, hide Separator */}
			<Separator
				isGroup
				current={sectionIndex + 1}
				summaryLength={summaryScreenLength}
			/>
		</>
	);
}

export default Content;
