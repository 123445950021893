export enum Service {
	formApplication = 'submit',
	formConfig = 'config',
	formMandant = 'mandant',
	formPath = 'formular',
	formUpload = 'upload',
	agencyByNumber = 'agn',
	agencyLocation = 'location',
	agencyContext = 'context',
	agencyContextFilter = 'NACHGELAGERTE_BERATERSUCHE',
	tariffCalculation = 'tariffing',
	tariffIds = 'tariffIds',
	ibanValidation = 'validate/deiban',
}
